import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'
import Img from "gatsby-image"


export default class Index extends React.Component {
  componentDidMount(){
    document.getElementById('lang-switch').href = 'https://e-multicontent.com/career/'
  }

  render() {
    const { data } = this.props
    return (
      <>
      <Helmet titleTemplate="Dołącz do Naszego zespołu! - praca copywriter Wrocław | e-multicontent.pl">
        <title>Dołącz do Naszego zespołu! - praca copywriter Wrocław | e-multicontent.pl</title>
        <meta name="description" content={'Myślisz, że szukamy właśnie Ciebie? Odezwij się do nas przez formularz kontaktowy! ✔️'}/>
        <meta name='image' content={'https://e-multicontent.pl/img/meta/kariera-meta.jpg'} />
        <meta name='keywords' content={'praca, copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce, praca copywriter Wrocław'} />
        <meta property="og:description" content={'Myślisz, że szukamy właśnie Ciebie? Odezwij się do nas przez formularz kontaktowy! ✔️'}/>
        <meta property='og:title' content={'Dołącz do Naszego zespołu! | e-multicontent.pl'} />
        <meta property='og:image' content={'https://e-multicontent.pl/img/meta/kariera-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.pl/kariera/'}/>

        <link rel="alternate" href="https://e-multicontent.com/career/" hrefLang="en-gb" />
        <link rel="alternate" href="https://e-multicontent.com/career/" hrefLang="en-us" />
        <link rel="canonical" href="https://e-multicontent.pl/kariera/"  />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.pl" />
        <meta property="twitter:url" content="https://e-multicontent.pl/kariera/" />
        <meta name="twitter:title" content="Dołącz do Naszego zespołu! | e-multicontent.pl" />
        <meta name="twitter:description" content="Myślisz, że szukamy właśnie Ciebie? Odezwij się do nas przez formularz kontaktowy! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.pl/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Kariera",
            "item": "https://e-multicontent.pl/kariera/"
          }]
        })}
        </script>

      </Helmet>
      <Layout style={{backgroundColor:'rgba(255,255,255,0.0)'}}>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>Kariera</b></span>
      </nav>
      <div>
      <Img fluid={data.file.childImageSharp.fluid}
      loading={'eager'}
      className="career-image"
      alt="Kariera w e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
      style={{
        marginTop:'0px',
        width: '100%',
        position: 'absolute',
        minHeight: '900px',
        zIndex: '-1',
      }} />
      <div className="columns" style={{padding:'5%',color:'white'}}>
        <div className="column c-col" style={{marginTop:'5%',padding:'2%',backgroundColor:'rgba(0,0,0,.6)',borderRadius:'25px',height:'min-content'}}>

        <h1 style={{color:'#36b3d2'}} className="title"><b>Dołącz do zespołu!</b></h1>
        <h2  className="subtitle"><strong style={{color:'white'}}>Agencja copywriterska praca</strong></h2>
        <p style={{color:'white'}}>Każdy tydzień przynosi w naszej agencji nowe wyzwania i nowe projekty. Dlatego cały czas szukamy osób, które mogłyby nas zdalnie wesprzeć w ich realizacji. Mamy klientów z całego świata i obecnie tworzymy dla nich teksty w języku polskim oraz kilkudziesięciu językach obcych, między innymi po angielsku, niemiecku, rumuńsku, węgiersku, francusku, słoweńsku czy też po słowacku.</p>
        <br></br>
        <h2 style={{color:'#36b3d2'}} className="title"><b>Kogo szukamy?</b></h2>
        <h3  className="subtitle"><strong style={{color:'white'}}>Copywriter praca Wrocław</strong></h3>
        <h4 style={{color:'#36b3d2'}} className="subtitle"><b>Osób:</b></h4>
        <ul className="subtitle" style={{listStyle: 'disc',color:'white',paddingLeft:'5%',paddingTop:'2%'}}>
        <li>Biegle mówiących w dowolnym języku obcym i świetnie posługujących się słowem pisanym, w skrócie <b>native speakerów-copywriterów</b>.</li>
        <li>Znających zasady poprawnej polszczyzny i świetnie posługujących się słowem pisanym, czyli <b>copywriterów tworzących treści w j. polskim</b>.</li>
        <li>Znających podstawy <b>komunikacji marketingowej oraz SEO</b>.</li>
        <li>Potrafiących <b>pracować na krótkich terminach</b>.</li>
        </ul>

        <br></br>


        </div>


        <div className="column" style={{margin:'5% 1% 0% 1%',padding:'2%',textAlign:'center',paddingBottom:'0px',backgroundColor:'rgba(0,0,0,.6)',borderRadius:'25px'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexWrap:'wrap',height:'100%'}}>
            <div>
            <img src="/img/thank-you-upl.svg" height="186px" width="200px" alt="formularz rekrutacyjny" />
            <br /><br />
            <h2 className="subtitle" style={{color:'white'}}>Wypełnij nasz formularz rekrutacyjny:</h2>
            <p style={{color:'white'}} className="subtitle">Myślisz, że szukamy <b>właśnie Ciebie</b>? <br></br> Odezwij się do nas przez <a href="https://forms.gle/HepPbDu75aB68DnY7" target="_blank" rel="noopener noreferrer">formularz</a>!</p>
            <br /><br />
            <a target="_blank" style={{fontSize:'1.5em'}} className="btn" rel="noopener noreferrer" href="https://forms.gle/HepPbDu75aB68DnY7">Wyślij zgłoszenie! <img width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="formularz rekrutacyjny Agencja Copywriterska - tłumaczenia i teksty, seo dla stron internetowych, e-commerce i na marketplace" src='/img/angle-right.svg'/></a>
            <br /><br />
            </div>
          </div>
        </div>


      </div>

      </div>

      <section className="section" style={{backgroundColor:'white',zIndex:'1',marginTop:'25px',width:'100%'}}>
      <div style={{marginTop:'0px',marginBottom:'0px'}} align="center">
      <br></br><br></br>
      <p style={{color:'#222222'}}><b>Chcesz wysłać maila?</b></p>
      <br></br>
      <p><a href="mailto:biuro@e-multicontent.pl" rel="noopener noreferrer" className="btn" target="_blank">biuro@e-multicontent.pl</a></p>
      <br></br>

      <p style={{color:'#222222'}}><b>Czy może wolisz porozmawiać?</b></p>
      <br></br>
      <p><a href="tel:+48713074517" rel="noopener noreferrer" className="btn" target="_blank">+48 71 307 45 17</a></p>
      <br></br>




      <br></br>


      <h3 style={{color:'#29b1ff'}}>Złóż nam wizytę przy najbliższej okazji.</h3>
      <p>Pamiętaj o wcześniejszym ustaleniu terminu spotkania z Nami.</p>
      <br></br>
      <h4 style={{color:'#222222'}}>Katarzyńska 1 F,<br></br> 55-010 Radwanice</h4>
      <sub>Biuro czynne w godzinach od <b>8 do 16</b> od <b>poniedziałku do piątku.</b></sub>
      <br></br>
      <br></br>
      <br></br>
      <div>
      <Link align="center" className="btn" style={{zIndex:'10000'}} to="/"> Wróć na stronę. </Link>
      </div>
      </div>


      </section>
      </Layout>
      </>
    )
  }

}


export const query = graphql`
  query FilesK{
    file(relativePath: {regex: "/optimized/zespol.jpg/"}) {
      childImageSharp {
        fluid(fit: COVER) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`
